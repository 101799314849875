<div
  matDialogTitle
>
  <h2 class="error-text">Cannot Generate IB Request Letter</h2>
</div>

<div matDialogContent>
  @for (error of errorList; track error) {
    <p>
      {{error}}
    </p>
  }
</div>

<mat-dialog-actions>
  <button
    (click)="close()"
    mat-stroked-button
  >
    <mat-icon>
      close
    </mat-icon>
    Close
  </button>
</mat-dialog-actions>