import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BaseComponent } from '../../../view/base.component';
import { FennecSnackbarService } from '../../../dialog/fennec-snackbar/fennec-snackbar.service';
import { Logger } from '../../../util/logger';

@Component({
  selector: 'lib-ib-request-verification-dialog',
  templateUrl: './ib-request-verification-dialog.component.html',
  styleUrl: './ib-request-verification-dialog.component.scss'
})
export class IbRequestVerificationDialogComponent extends BaseComponent {
  protected override log: Logger = new Logger("IbRequestVerificationDialogComponent");

  errorList: string[];

  constructor(
    public dialogRef: MatDialogRef<IbRequestVerificationDialogComponent>,
    protected snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();

    if (data.errorList != null) {
      this.errorList = data.errorList;
    }
  }

  close = () => {
    this.dialogRef.close();
  }

}
