import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HealthCarePlanLOBUpdatePacket } from 'projects/xf-common/src/lib/model/net/healthCarePlanLOBUpdatePacket';
import { Observable } from 'rxjs/internal/Observable';
import { BaseService, Logger, EnvironmentService, HealthCarePlanPacket } from "xf-common";

@Injectable()
export class HealthCarePlanLobService extends BaseService {
  baseEndpoint = this.apiEndpoint("health-care-plan-lob");

  constructor(
    public override httpClient: HttpClient,
    private environment: EnvironmentService
  ) {
    super(new Logger("HealthCarePlanLobService"));
  }

  getHealthCarePlanLOB(hcPlanId: number, lob: string): Observable<any> {
    return this.httpClient.get<any>(this.baseEndpoint + "/" + hcPlanId.toString() + "/" + lob);
  }

  getHealthCarePlanLOBAppealLevelTypeAppealTypes(hcPlanId: number, lob: string): Observable<any> {
    return this.httpClient.get<any>(this.baseEndpoint + "/appeal-level-type-appeal-type/" + hcPlanId.toString() + "/" + lob);
  }  

  putHealthCarePlanLOB(payload: HealthCarePlanLOBUpdatePacket): Observable<any> {
    return this.httpClient.put<any>(this.baseEndpoint, payload);
  }

  genIBRequestLetterTest(hcPlanLOBId: number, attachmentType: string): Observable<any> {
    return this.httpClient.put<any>(this.baseEndpoint + "/gen-test-ib-request-letter/" + hcPlanLOBId.toString() + "/" + attachmentType, {});
  }

  genProviderCoverLetterTest(hcPlanLOBId: number, attachmentType: string): Observable<any> {
    return this.httpClient.put<any>(this.baseEndpoint + "/gen-test-provider-cover-letter/" + hcPlanLOBId.toString() + "/" + attachmentType, {});
  }

 }
