export class SendEmailDialogConfig {
  dialogHeader: string = "Send Email";
  sendEmailContext: string = "BASIC";
  miCaseId: number = -1;
  attachmentId: number = -1;
  attachmentName: string = "Unknown";

  // Used to prompt default values on the UI dialog form.
  inputName?: string = null;
  inputEmailAddress?: string = null;
  inputSubject?: string = null;
  inputBody?: string = null;

}