import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

import { Ub04Service } from '../ub04.service';
import { Logger } from '../../util/logger';
import { MICaseService } from '../../mi-case/mi-case.service';
import { SingleChoiceDialogComponent } from 'projects/xf-common/src/public-api';
import { BaseComponent } from '../../view/base.component';
import { FennecSnackbarService } from '../../dialog/fennec-snackbar/fennec-snackbar.service';
import { IbRequestVerificationDialogComponent } from './ib-request-verification-dialog/ib-request-verification-dialog.component';

@Component({
  selector: 'app-ub04-gen-ib-request-dialog',
  templateUrl: './ub04-gen-ib-request-dialog.component.html',
  styleUrls: ['./ub04-gen-ib-request-dialog.component.css']
})
export class UB04GenIBRequestDialogComponent extends BaseComponent implements OnInit  {

  protected override log: Logger = new Logger("UB04GenIBRequestDialogComponent");

  ub04Id: number = -1;

  constructor(
    public dialogRef: MatDialogRef<UB04GenIBRequestDialogComponent>,
    protected snack: FennecSnackbarService,
    private ub04Service: Ub04Service,
    private miCaseService: MICaseService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.ub04Id = data.ub04Id;
  }

  ngOnInit() {

  }

  fetchData() {

  }

  onSubmit() {

  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  genIBRequestLetter(letterNo: number) {
    let attachmentType = "IB_REQUEST_TEMPLATE_1";
    if (letterNo === 2) {
      attachmentType = "IB_REQUEST_TEMPLATE_2";
    } else if (letterNo === 3) {
      attachmentType = "IB_REQUEST_TEMPLATE_3";
    }

    this.performXFRequest({
      requestDescription:"GET IB Request Verification",
      requestFn: this.ub04Service.verifyIBRequestLetterCanBeGenerated,
      fnParams: [this.ub04Id, attachmentType],
      onSuccess: data => {
        if (data.hasErrors) {
          
          const matDialogConfig = new MatDialogConfig();
          matDialogConfig.disableClose = true;
          matDialogConfig.height = "auto";
          matDialogConfig.width = "auto";
          matDialogConfig.data = {
            errorList: data.errorList
          };
          const dialogRef = this.matDialog.open(IbRequestVerificationDialogComponent, matDialogConfig);

        }else {

          const matDialogConfig = new MatDialogConfig();
          matDialogConfig.disableClose = true;
          matDialogConfig.height = "auto";
          matDialogConfig.width = "auto";
          matDialogConfig.data = {
            question: "Generate IB Request Letter for UB04 " + this.ub04Id + " using " + attachmentType + "?"
          };
          const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
          dialogRef.afterClosed().subscribe(result => {
            if (result.confirm) {
              this.ub04Service.genIBRequestLetter(this.ub04Id, attachmentType).subscribe((serverResponse) => {
                if (serverResponse.hasErrors) {
                  this.snack.showErrorSnack("Error submitting request to generate IB request letter: " + serverResponse.consolidatedErrorMessage);
                } else {
                  this.snack.showWorkerSubmitSnack("Generate IB Request Letter submitted!");
                  const returnObj = {
                    confirm: true
                  }
                  this.dialogRef.close(returnObj);
                }
              }); 
            }
          });

        }
      },
      onError: errString => {
        super.showErrorSnack("Error verifying IB Request.  " + errString);
      }
    })

  }

}
