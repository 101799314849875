
<div>
  <div>
    <form [formGroup]="lobSelectFormGroup" class="lob-select-form">
      <mat-form-field>
        <mat-label>Line of Business</mat-label>
        <mat-select formControlName="lob" panelClass="alt-theme">
          <mat-option *ngFor="let lob of lineOfBusinessList" [value]="lob.value">
            {{ lob.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button mat-icon-button color="accent" style="margin-left: 10px;"
      (click)="onRefreshInfo()">
      <mat-icon>refresh</mat-icon>
      </button>            
    </form>
  </div>

  <div>

    <mat-table [dataSource]="recordList">
      <ng-container matColumnDef="appealLevelTypeLabel">
        <mat-header-cell *matHeaderCellDef>Appeal Level</mat-header-cell>
        <mat-cell *matCellDef="let rec"> {{rec.appealLevelTypeLabel}} </mat-cell> 
      </ng-container>

      <ng-container matColumnDef="appealTypeLabel">
        <mat-header-cell *matHeaderCellDef>Appeal Type</mat-header-cell>
        <mat-cell *matCellDef="let rec"> {{rec.appealTypeLabel}} </mat-cell> 
      </ng-container>  
      
      <ng-container matColumnDef="appealCoverLetterTemplate">
        <mat-header-cell *matHeaderCellDef>Appeal Cover Letter Template</mat-header-cell>
        <mat-cell *matCellDef="let rec">
          <ng-container *ngIf="rec.attachments?.length <= 0"> 
            <input
            #fileUploadInput
            type="file"
            name="fileUpload"
            data-id="rec.id"
            (change)="addFile(fileUploadInput)"
            (click)="selectForUpload(rec.id)"
            [hidden]="true">

            <div class="button-group">
              <button
                [disabled]="uploading"
                class="btn"
                mat-raised-button
                color="primary"
                #upload
                (click)="fileUploadInput.click()">
                  <mat-icon>upload</mat-icon> Choose file
              </button>

              <button
                class="btn hcplan-log-provider-rpt-logo-add-btn"
                mat-raised-button
                color="accent"
                (click)="onSaveAttachment(rec.id, 'APPEAL_RESPONSE_LETTER_TEMPLATE')"
                [disabled]="disableSave(rec.id)"
                >
                <mat-icon>save</mat-icon>
                {{uploading ? "Uploading..." : "Save"}}
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="rec.attachments?.length > 0">
            <span style="margin-right: 5px">{{rec.attachments[0].name}}</span>
            
            <button
            class="btn hcplan-log-provider-rpt-logo-add-btn"
            mat-raised-button
            matTooltip="Download template"
            color="accent"
            (click)="downloadAttachment(rec.attachments[0].id)">
            <mat-icon>download</mat-icon>
            </button>

            <button
            class="btn hcplan-log-provider-rpt-logo-add-btn"
            mat-raised-button
            matTooltip="Delete template"
            color="accent"
            (click)="deleteAttachmentConfirmation(rec.attachments[0].attachmentRelationalMapId)">
            <mat-icon>delete</mat-icon>
          </button> 
          </ng-container>
        </mat-cell>
      </ng-container>       
      
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns; let i=index"
        [tabindex]="i + 1"
        [attr.data-index]="i"
        [ngClass]="{ hovered: row.hovered }"
        [ngStyle]="{'background-color': row.attachments?.length > 0 ? 'yellow' : null }"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
      ></mat-row>
    </mat-table>
    <!--
    <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="defaultPageSizeOptions"></mat-paginator>
    -->

  </div>
  
</div>
