import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AttachmentComponent } from '@app/attachment/attachment.component';
import { Logger, BaseComponent, FennecSnackbarService } from "xf-common";

@Component({
  selector: 'app-activity-diary-attachment-dialog',
  templateUrl: './activity-diary-attachment-dialog.component.html',
  styleUrls: ['./activity-diary-attachment-dialog.component.scss']
})
export class ActivityDiaryAttachmentDialogComponent extends BaseComponent implements OnInit, AfterViewInit {
  log: Logger = new Logger("ActivityDiaryAttachmentDialogComponent");

  attachmentId: number = -1;
  miCaseId: number = -1;
  @ViewChild('attachmentComponent')
  attachmentComponent?: AttachmentComponent;
  context: string = 'MICASE';

  constructor(
  @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialogRef: MatDialogRef<ActivityDiaryAttachmentDialogComponent>,
    protected snack: FennecSnackbarService,
  ) {
    super();
    this.context = this.data?.activityDiaryDomain;
  }

  ngAfterViewInit() {
    
  }

  ngOnInit() {
    this.attachmentId = this.data.attachmentId;
    this.miCaseId = this.data.miCaseId;
  }

  onClose() {
    this.dialogRef.close();
  }

}
