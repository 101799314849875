<ng-template #innerContent>

  <div class="filter-wrapper">
    <mat-accordion *ngIf="miCaseId === '-1' && screenMode === 'MASTER_LIST'" class="filters">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Filters
        </mat-expansion-panel-header>

        <form [formGroup]="formGroup">
          <mat-radio-group formControlName="userId">
            <!-- <mat-label>
              User ID
            </mat-label> -->
            <mat-radio-button
              class="radio-btn"
              value="MINE"
            >My Activity
            </mat-radio-button>

            <mat-radio-button
              value="ALL"
            >Activity for All Users
            </mat-radio-button>
          </mat-radio-group>

          <ng-container *ngIf="showActivityDiaryFilterOptions">
            <mat-form-field appearance="outline" class="activity-diary-domain-select">
              <mat-label>Domain</mat-label>
              <mat-select formControlName="activityDiaryDomain">
                <mat-option *ngFor="let add of activityDiaryDomains" [value]="add">
                  {{ add }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="activity-diary-message-type-select">
              <mat-label>Message Type</mat-label>
              <mat-select formControlName="activityDiaryMessageType">
                <mat-option *ngFor="let admt of activityDiaryMessageTypes" [value]="admt">
                  {{ admt }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <button mat-button mat-raised-button color="accent" class="clear-filters-button"
                    (click)="onClearFilters()">
              <mat-icon>clear</mat-icon>
            </button>

          </ng-container>

        </form>
      </mat-expansion-panel>
    </mat-accordion>

    <div *ngIf="screenMode === 'NOTIFICATIONS' && activityDiaryList.data.length > 0">
      <button mat-raised-button color="primary"
        (click)="onDeleteAllNotificationsPrompt()">
      <mat-icon>delete</mat-icon> Delete All Notifications
      </button>
    </div>

    <button class='activity-diary-refresh-button' mat-raised-button mat-icon-button color="accent"
            (click)="onRefreshList()">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>

  <mat-table class="table table-condensed table-responsive"
             [dataSource]="activityDiaryList">

    <!-- createdDate Column -->
    <ng-container matColumnDef="createdDate">
      <mat-header-cell *matHeaderCellDef> Date</mat-header-cell>
      <mat-cell *matCellDef="let ad" class="mat-cell-createdDate"> {{ad.createdDateString}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="activityDiaryDomain">
      <mat-header-cell *matHeaderCellDef> Domain</mat-header-cell>
      <mat-cell *matCellDef="let ad" class="mat-cell-domain"> {{ad.activityDiaryDomain}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="miCaseId">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Case</mat-header-cell>
      <mat-cell *matCellDef="let ad" class="mat-cell-case">

        <button *ngIf="ad.miCaseId != null && ad.miCaseId >= 0" mat-button mat-raised-button color="accent"
                class="activity-diary-mi-case-btn"
                [routerLink]="['/mi-case/', ad.miCaseId]"
                routerLinkActive="active">
          <mat-icon [inline]="true" matListIcon>folder</mat-icon>
          {{ad.miCaseId}}
        </button>

      </mat-cell>
    </ng-container>

    <!-- relatedId Column -->

    <ng-container matColumnDef="relatedId">
      <mat-header-cell *matHeaderCellDef> Related ID</mat-header-cell>
      <mat-cell *matCellDef="let ad" class="mat-cell-relatedId">

        <button *ngIf="ad.activityDiaryDomain == 'EDI_RUN'" mat-button mat-raised-button color="accent"
                class="activity-diary-mi-case-btn"
                [routerLink]="['/edi-run']"
                routerLinkActive="active">
          <mat-icon [inline]="true" matListIcon>cached</mat-icon>
          EDI Run:   {{ad.relatedId}}
        </button>

        <button *ngIf="ad.activityDiaryDomain == 'ITEMIZED'" mat-button mat-raised-button color="accent"
                class="activity-diary-mi-case-btn"
                [routerLink]="['/mi-case', ad.miCaseId, 'itemized', ad.relatedId]"
                routerLinkActive="active">
          <mat-icon [inline]="true" matListIcon>system_update</mat-icon>
          Itemized:  {{ad.relatedId}}
        </button>

        <button *ngIf="ad.activityDiaryDomain == 'ITEMIZED_REVISION' || ad.activityDiaryDomain == 'APE'"
                mat-button mat-raised-button color="accent" class="activity-diary-mi-case-btn"
                [routerLink]="['/mi-case', ad.miCaseId, 'itemized-revision', ad.relatedId]"
                routerLinkActive="active">
          <mat-icon [inline]="true" matListIcon>tap_and_play</mat-icon>
          Revision:  {{ad.relatedId}}
        </button>

        <button *ngIf="ad.activityDiaryDomain == 'UB04'" mat-button mat-raised-button color="accent"
                class="activity-diary-mi-case-btn"
                [routerLink]="['/mi-case', ad.miCaseId, 'ub04', ad.relatedId]"
                routerLinkActive="active">
          <mat-icon [inline]="true" matListIcon>folder_special</mat-icon>
          UB04:  {{ad.relatedId}}
        </button>

        <button *ngIf="ad.activityDiaryDomain == 'XFWF_TASK'" mat-button mat-raised-button color="accent"
                class="activity-diary-mi-case-btn"
                [routerLink]="['/']"
                routerLinkActive="active">
          <mat-icon [inline]="true" matListIcon>playlist_add_check</mat-icon>
          XFWF Task: {{ad.relatedId}}
        </button>

        <button *ngIf="ad.activityDiaryDomain == 'ATTACHMENT'"
                mat-button mat-raised-button color="accent"
                class="activity-diary-mi-case-btn"
                (click)="openAttachmentDialog(ad.relatedId, ad.miCaseId, ad.activityDiaryDomain)">
          <mat-icon [inline]="true" matListIcon>attach_file</mat-icon>
          Attachment: {{ad.relatedId}}
        </button>

        <!-- On EMAIL domain activity diary records, if an email was sent out with an attachment we 
             fish out the attachmentID from the 'data' portion of the activity diary record -->
        <button 
          *ngIf="ad.activityDiaryDomain == 'EMAIL' && ad.attachmentId !== null"
          mat-button mat-raised-button color="accent"
          class="activity-diary-mi-case-btn"
          (click)="openAttachmentDialog(ad.attachmentId, ad.miCaseId, ad.activityDiaryDomain)">
          <mat-icon [inline]="true" matListIcon>attach_file</mat-icon>
            Attachment: {{ad.attachmentId}}
        </button>        

      </mat-cell>
    </ng-container>

    <!-- activityDiaryMessageType Column -->
    <ng-container matColumnDef="activityDiaryMessageType">
      <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
      <mat-cell *matCellDef="let ad" class="mat-cell-type"> {{ad.activityDiaryMessageType}} </mat-cell>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="messageText">
      <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
      <mat-cell *matCellDef="let ad" class="mat-cell-messageText">
        <table>
          <tr *ngIf="ad.errorFlag === true" class="activity-diary-message-text-errors">
            <td>ERRORS DETECTED</td>
          </tr>
          <tr *ngIf="ad.errorFlag === false" class="activity-diary-message-text">
            <td>{{ad.messageText}}</td>
          </tr>
          <tr *ngIf="(ad.beforeData !== null && ad.beforeData !== undefined) || (ad.afterData !== null && ad.afterData !== undefined)">
            <td>
              <button *ngIf="ad.beforeData !== null && ad.beforeData !== undefined" 
              style="width: 100px; margin-right: 3px; margin-bottom: 5px" (click)="viewJson('Before Data', ad.beforeData)">
                Before Data
              </button>
              <button *ngIf="ad.afterData !== null && ad.afterData !== undefined" 
                  style="width: 100px" (click)="viewJson('After Data', ad.afterData)">
                After Data
              </button>  
              <br>
              <span class="global-data-element-value-span" *ngFor="let dp of ad.diffProperties.split(',')"> [{{ dp }}]</span> 
            </td>
          </tr>
        </table>
        <!--
        <div *ngIf="ad.errorFlag === true" class="activity-diary-message-text-errors"> ERRORS DETECTED</div>
        <div *ngIf="ad.errorFlag === false" class="activity-diary-message-text">
          {{ad.messageText}}
        </div>
        <div *ngIf="(ad.beforeData !== null && ad.beforeData !== undefined) || (ad.afterData !== null && ad.afterData !== undefined)"> 
          <button *ngIf="ad.beforeData !== null && ad.beforeData !== undefined" 
              style="width: 100px; margin-right: 3px" (click)="viewJson('Before Data', ad.beforeData)">
            Before Data
          </button>
          <button *ngIf="ad.afterData !== null && ad.afterData !== undefined" style="width: 100px" (click)="viewJson('After Data', ad.afterData)">
            After Data
          </button>  
          {{ ad.diffProperties }}        
        </div> -->
      </mat-cell>
    </ng-container>

    <!-- createdBy Column -->
    <ng-container matColumnDef="createdBy">
      <mat-header-cell *matHeaderCellDef> User</mat-header-cell>
      <mat-cell *matCellDef="let ad" class="mat-cell-createdBy">
        {{ad.createdBy}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="errorFlag">
      <mat-header-cell *matHeaderCellDef>
        <mat-icon [inline]="true" ngClass="warning-icon">warning</mat-icon>
        <pre> </pre>
        Errors
      </mat-header-cell>
      <mat-cell *matCellDef="let ad" class="mat-cell-errors">
        <mat-icon [inline]="true" [style]="{'cursor': ad.errorFlag === false ? 'not-allowed' : 'help'}"
                  (click)="ad.errorFlag === true ? openErrorDialog(ad.messageText) : null"
                  [ngStyle]="{'color': ad.errorFlag === false ? 'green' : 'red'}">
          {{ ad.errorFlag === true ? 'report_problem' : 'check'}}
        </mat-icon>
        <pre> </pre>
        <div *ngIf="ad.errorFlag === true" class="error-text" (click)="openErrorDialog(ad.id)"> Details</div>
        <pre> </pre>
      </mat-cell>
      <!-- [ngStyle]="{'background-color': displayColumns.errorFlag  == 'true' ? 'red': ad.errorFlag == 'false' ? 'green' : ''}" -->
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
  </mat-table>
  <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize"
                 [pageSizeOptions]="pageSizeOptions"></mat-paginator>
</ng-template>

<ng-template #refreshButton>
</ng-template>

<ng-container *ngIf="miCaseId === '-1'; else innerContent">

  <lib-page #page [isLoading]="isLoading">

    <ng-container pageTitle>
      <span *ngIf="screenMode === 'MI_CASE' || screenMode === 'MASTER_LIST'">Activity Diaries</span>
      <span *ngIf="screenMode === 'NOTIFICATIONS'">Notifications</span>
      <ng-container *ngTemplateOutlet="refreshButton"></ng-container>
    </ng-container>

    <ng-container pageContent>
      <ng-container *ngTemplateOutlet="innerContent"></ng-container>
    </ng-container>
  </lib-page>
</ng-container>
