<div matDialogTitle>
  <h2>{{dialogHeader}}</h2>
</div>

<mat-dialog-content class="alt-theme">
  <form [formGroup]="formGroup" class="form">
    <mat-form-field appearance="outline">
      <mat-label>Addressee Name</mat-label>
      <input matInput formControlName="toName">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Email Address</mat-label>
      <input matInput formControlName="toAddress">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Subject Line</mat-label>
      <input matInput formControlName="subject">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Message Body</mat-label>
      <textarea matInput formControlName="messageBody" rows="5" cdkTextareaAutosize></textarea>
    </mat-form-field>
  </form>

  <div *ngIf="sendEmailDialogConfig.attachmentId !== null && sendEmailDialogConfig.attachmentId >= 0" style="color: gray">
    <mat-icon>attachment</mat-icon> {{sendEmailDialogConfig.attachmentName}}
  </div>

</mat-dialog-content>


<mat-dialog-actions>
  <button
    mat-raised-button
    color="accent"
    (click)="sendEmail()"
    [disabled]="!formGroup.valid"
  >
    <mat-icon>email</mat-icon>
    Send Email
  </button>

  <button
    mat-stroked-button
    (click)="close()"
  >
    <mat-icon>close</mat-icon>
    Close
  </button>
</mat-dialog-actions>