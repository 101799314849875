import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AttachmentComponent } from '../attachment.component';
import { Logger, BaseComponent, FennecSnackbarService } from "xf-common";

@Component({
  selector: 'app-attachment-select-dialog',
  templateUrl: './attachment-select-dialog.component.html',
  styleUrls: ['./attachment-select-dialog.component.scss']
})
export class AttachmentSelectDialogComponent extends BaseComponent implements OnInit, AfterViewInit {
  log: Logger = new Logger("AttachmentSelectDialogComponent");

  context: string = 'MICASE';
  attachmentId: number = -1;
  miCaseId: number = -1;

  @ViewChild('attachmentComponent')
  attachmentComponent?: AttachmentComponent;

  constructor(
  @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialogRef: MatDialogRef<AttachmentSelectDialogComponent>,
    protected snack: FennecSnackbarService,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.attachmentComponent?.attachmentSelected.subscribe((att:any) => {
      if (att !== null && att !== undefined && att.id >= 0) {
      let returnObj = {
        attachmentId: att.id,
        confirm: true
      };
        this.dialogRef.close(returnObj);
      }
    });
  }

  ngOnInit() {
    this.attachmentId = this.data.attachmentId;
    this.miCaseId = this.data.miCaseId;
  }

  onClose() {
    let returnObj = {
      attachmentId: -1,
      confirm: false
    };
    this.dialogRef.close();
  }

}
