import { Deserializable } from "../deserializable";
import { FilePacket } from "./filePacket";


export class AttachmentPacket extends Deserializable<AttachmentPacket>{
  id?: number;
  name?: string;
  miCaseId?: number;
  healthCarePlanLOBId?: number;
  healthCarePlanLOBAplLvlTypeAplTypeId?: number;
  externalObjectId?: string;
  attachmentType?: string;
  filePacket?: FilePacket;
  publishToWeb?: boolean;
}
