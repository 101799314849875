<lib-page>
  <ng-container pageTitle>
    <span>Providers</span>
    <button class="add" mat-flat-button (click)="onCreate()">
      <mat-icon>add_circle</mat-icon>
      New
    </button>
  </ng-container>
  <ng-container pageContent>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h4>Filters</h4>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="filter-options">
            <mat-form-field class="search-field" appearance="outline" color="accent">
              <mat-label>
                Provider Name
              </mat-label>
              <input matInput [(ngModel)]="queryParams.providerName" autofocus (keydown.enter)="searchProviders()">
            </mat-form-field>
            <mat-form-field class="search-field" appearance="outline" color="accent">
              <mat-label>
                First Name
              </mat-label>
              <input matInput [(ngModel)]="queryParams.firstName" autofocus (keydown.enter)="searchProviders()">
            </mat-form-field>
            <mat-form-field class="search-field" appearance="outline" color="accent">
              <mat-label>
                Last Name
              </mat-label>
              <input matInput [(ngModel)]="queryParams.lastName" autofocus (keydown.enter)="searchProviders()">
            </mat-form-field>
            <mat-form-field class="search-field" appearance="outline" color="accent">
              <mat-label>
                NPI Number
              </mat-label>
              <input matInput [(ngModel)]="queryParams.npiNumber" autofocus (keydown.enter)="searchProviders()">
            </mat-form-field>
            <mat-form-field class="search-field" appearance="outline" color="accent">
              <mat-label>
                Type
              </mat-label>
              <mat-select [(ngModel)]="queryParams.type" (selectionChange)="searchProviders()">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let type of providerTypes" [value]="type.value">{{type.label}}</mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-flat-button color="accent" (click)="searchProviders()">
              Search
            </button>
            <button mat-stroked-button color="accent" (click)="clearSearch()">
              Clear
            </button>
          </div>


          <!-- Inner Accordion -->
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h4>EIN Search</h4>
                </mat-panel-title>
              </mat-expansion-panel-header>
                <div class="alt-theme">
                  <mat-form-field appearance="outline" style="width:80%;">
                    <mat-label>EIN/Federal Tax Number</mat-label>
                    <input matInput [(ngModel)]="federalTaxNumber">
                  </mat-form-field>

                  <button
                    mat-flat-button
                    color="accent"
                    (click)="providerEINSearch()"
                  >
                    EIN Search
                  </button>

                  <button mat-stroked-button color="accent" (click)="clearSearch()">
                    Clear
                  </button>
                </div>
            </mat-expansion-panel>
          </mat-accordion>

        </mat-expansion-panel>
      </mat-accordion>
    <mat-table [dataSource]="providerList" class="mat-elevation-z8">

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
        <mat-cell *matCellDef="let p">
          {{p.id}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Provider Name </mat-header-cell>
        <mat-cell *matCellDef="let p">{{p.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef> First Name </mat-header-cell>
        <mat-cell *matCellDef="let p">{{p.firstName}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef> Last Name </mat-header-cell>
        <mat-cell *matCellDef="let p">{{p.lastName}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
        <mat-cell *matCellDef="let p"> {{p.address}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
        <mat-cell *matCellDef="let p"> {{p.providerType}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="npiNumber">
        <mat-header-cell *matHeaderCellDef> NPI Number </mat-header-cell>
        <mat-cell *matCellDef="let p"> {{p.npiNumber}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let p">
          <mat-menu #actionMenu="matMenu" class="action-menu">
            <a [routerLink]="['/provider/', p.id]" routerLinkActive="active">
              <button
                mat-menu-item
              >
                <mat-icon>edit</mat-icon>
                Edit
              </button>
            </a>

            <button
              mat-menu-item
              (click)="onDeleteProvider(p)"
            >
              <mat-icon>delete</mat-icon>
              Delete
            </button>
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="actionMenu" aria-label="Row Action Items">
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row 
        *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{ hovered: row.hovered }"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
      >
      </mat-row>
    </mat-table>
    <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </ng-container>
</lib-page>
