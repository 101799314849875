import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent, FennecSnackbarService, Logger, SingleChoiceDialogComponent } from 'xf-common';
import { ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MiCaseAppealLevelService } from './mi-case-appeal-level.service';
import { MiCaseAppealLevelCreateDialogComponent } from './mi-case-appeal-level-create-dialog/mi-case-appeal-level-create-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { ItemizedRevisionSelectDialogComponent } from '../../itemized/itemized-revision/itemized-revision-select-dialog/itemized-revision-select-dialog.component';
import { ItemizedRevisionService } from '../../itemized/itemized-revision/itemized-revision.service';
import { KeycloakService } from 'keycloak-angular';
import { AttachmentSelectDialogComponent } from '../../attachment/attachment-select-dialog/attachment-select-dialog.component';
import { AttachmentComponent } from '../../attachment/attachment.component';

@Component({
  selector: 'app-mi-case-appeal-level',
  templateUrl: './mi-case-appeal-level.component.html',
  styleUrls: ['./mi-case-appeal-level.component.scss']
})
export class MiCaseAppealLevelComponent extends BaseComponent implements OnInit, AfterViewInit {

  protected override log: Logger = new Logger("MICaseAppealLevelComponent");
  
  @Input()
  miCaseId: string = "-1";

  appealLevelList: any [] = [];
  selectedAppealLevel: any = null;
  itemizedRevisionInfo: any = null;

  canUserGenerateAppealReport = false;

  @ViewChild("paginator")
  paginator?: MatPaginator;

  defaultPageSize:number = 5;
  totalRowCount:number = 0;

  constructor(
    private itemizedRevisionService: ItemizedRevisionService,
    public matDialog: MatDialog,
    protected snack: FennecSnackbarService,
    private miCaseAppealLevelService: MiCaseAppealLevelService,
    protected keycloakService: KeycloakService,
    private route: ActivatedRoute
  ) {
    super();
    if (this.route.parent) {
      this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? "-1";
    }
    const roles = keycloakService.getUserRoles();
    this.canUserGenerateAppealReport = roles.includes("APPEAL_REPORT_GENERATE") 
    || roles.includes("ADMIN");

   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getAppealLevelList();
      });
    }
    this.getAppealLevelList();
  }

  createAppealLevel(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      mode: "CREATE",
      appealType: null,
      miCaseId : parseInt(this.miCaseId)
    };
    const dialogRef = this.matDialog.open(MiCaseAppealLevelCreateDialogComponent, dialogConfig);
    // After the dialog is closed, subscribe to the returned result
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.selectedAppealLevel = null;
        this.getAppealLevelList();
      }
    });

  }

  editAppealLevel(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      mode: "EDIT",
      id: this.selectedAppealLevel.id,
      appealType: this.selectedAppealLevel.appealType,
      appealLevelType: this.selectedAppealLevel.appealLevelType,
      itemizedRevisionId: this.selectedAppealLevel.itemizedRevisionId,
      miCaseId : parseInt(this.miCaseId)
    };
    const dialogRef = this.matDialog.open(MiCaseAppealLevelCreateDialogComponent, dialogConfig);
    // After the dialog is closed, subscribe to the returned result
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.getAppealLevelList();
      }
    });

  }

  getAppealLevelList(): void {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    this.performXFRequest({
      requestDescription: "Get Appeal Level List",
      requestFn: this.miCaseAppealLevelService.getAppealLevelsForMICase,
      fnParams: [parseInt(this.miCaseId), first, pageSize],
      onResponse: (response: any) => {
        this.appealLevelList = response.data;
        if (this.appealLevelList.length > 0 && this.selectedAppealLevel === null) {
          this.selectedAppealLevel = this.appealLevelList[0];
        } else {
          // Make sure to refresh the selected appeal level data on a data refresh from the server.
          this.appealLevelList.forEach((alvl) => {
            if (this.selectedAppealLevel.id === alvl.id) {
              this.selectedAppealLevel = alvl;
            }
          });
        }
        this.getItemizedRevisionInfo();
        this.totalRowCount = response.totalRowCount;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  updateAppealLevelItemizedRevision(iRevId: number): void {
    const packet = {
      id: this.selectedAppealLevel?.id,
      itemizedRevisionId: iRevId,
      appealLevelType: this.selectedAppealLevel?.appealLevelType,
      appealType: this.selectedAppealLevel?.appealType,
      miCaseId: this.selectedAppealLevel?.miCaseId
    }
    this.performXFRequest({
      requestDescription: "Update Appeal Level iRev",
      requestFn: this.miCaseAppealLevelService.update,
      fnParams: [packet],
      onSuccess: (data: any) => {
        this.getAppealLevelList();
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  getItemizedRevisionInfo(): void {
    this.itemizedRevisionInfo = null;
    if (this.selectedAppealLevel?.itemizedRevisionId === null) {
      return;
    }
    this.itemizedRevisionService.getItemizedRevisionInfo(parseInt(this.selectedAppealLevel?.itemizedRevisionId))
      .subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.itemizedRevisionInfo = response.data;      
      }
    });
  }  

  getSelectedStyle(id: number) {
    if (id.toString() === this.selectedAppealLevel?.id.toString()) {
      return {
        "background-color": "powderblue"
      }
    } else {
      return {
        "background-color": "#E5E6EB"
      }
    }
  }

  selectAppealLevel(alvl: any) {
    this.selectedAppealLevel = alvl; 
    this.getItemizedRevisionInfo();   
  }

  selectItemizedRevision() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "80vw";
    dialogConfig.data = {
      miCaseId : parseInt(this.miCaseId),
      itemizedRevisionId: this.selectedAppealLevel?.itemizedRevisionId
    };
    const dialogRef = this.matDialog.open(ItemizedRevisionSelectDialogComponent, dialogConfig);
    // After the dialog is closed, subscribe to the returned result
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.updateAppealLevelItemizedRevision(result.itemizedRevisionId);
      }
    });
  }

  unlinkItemizedRevision() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      question:`Unlink Itemized Revision (${this.selectedAppealLevel.itemizedRevisionId}) from Appeal Level (${this.selectedAppealLevel.appealLevelTypeLabel})?`
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirm) {
        this.updateAppealLevelItemizedRevision(-1);
      }
    });
  }

  deleteAppealLevelPrompt(alvl: any) {
    this.selectedAppealLevel = alvl;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      question:`Delete Appeal Level (${this.selectedAppealLevel.appealLevelTypeLabel})?`
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirm) {
        this.deleteAppealLevel(alvl);
      }
    }); 
  }

  deleteAppealLevel(alvl: any) {
    this.performXFRequest({
      requestDescription: "Delete Appeal Level",
      requestFn: this.miCaseAppealLevelService.delete,
      fnParams: [alvl.id],
      onSuccess: (data: any) => {
        this.selectedAppealLevel = null;
        this.getAppealLevelList();
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  generateAppealReport = () => {
    
    let caseId: null | number = null;
    if (this.miCaseId != null) {
      caseId = parseInt(this.miCaseId);
    }

    const packet = {
      miCaseAppealLevelId: this.selectedAppealLevel.id,
      miCaseId: caseId,
      itemizedRevisionId: this.selectedAppealLevel.itemizedRevisionId,
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : `Generate Appeal Report for Appeal Level: ${this.selectedAppealLevel?.appealLevelTypeLabel} Revision Id:${this.selectedAppealLevel.itemizedRevisionId}?`,
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirm) {
        this.itemizedRevisionService.generateAppealReport(packet).subscribe((response) => {
          if (response.hasErrors) {
            this.snack.showErrorSnack("Error requesting Appeal Report");
          }else {
            this.snack.showWorkerSubmitSnack("Generating Appeal Report");
          }
        });
      }
    });
  }

  openAttachmentDialog() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.height = "auto";
    matDialogConfig.width = "100%";
    matDialogConfig.panelClass='custom-mat-dialog-panel';
    matDialogConfig.data = {
      attachmentId: this.selectedAppealLevel.id,
      miCaseId: parseInt(this.miCaseId),
      context: 'MICASEAPPEALLEVEL',
    };
    // const dialogRef = this.matDialog.open(AttachmentComponent, matDialogConfig);
    const dialogRef = this.matDialog.open(AttachmentSelectDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.linkUnlinkAttachment(result.attachmentId);
      }
    });
  }

  linkUnlinkAttachment(attachmentId: number) {
    this.performXFRequest({
      requestDescription: "Link Attachment to Appeal Level",
      requestFn: this.miCaseAppealLevelService.linkUnlinkAttachment,
      fnParams: [this.selectedAppealLevel.id, attachmentId],
      onSuccess: (data: any) => {
        this.getAppealLevelList();
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

}
