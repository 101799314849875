import { AfterViewInit, Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { AttachmentComponent } from '@app/attachment/attachment.component';
import { ActivityLogJsonDialogComponent, ActivityDiaryQueryRequestPacket, BaseComponent, 
  DateUtil, FennecSnackbarService, Logger, SingleChoiceDialogComponent 
} from 'xf-common';
import {
  ActivityDiaryAttachmentDialogComponent
} from '../activity-diary-attachment-dialog/activity-diary-attachment-dialog.component';
import { ActivityDiaryDialogComponent } from '../activity-diary-dialog/activity-diary-dialog.component';
import { ActivityDiaryService } from '../activity-diary.service';
import {  } from 'projects/xf-common/src/public-api';

@Component({
  selector: 'app-activity-diary-list',
  templateUrl: './activity-diary-list.component.html',
  styleUrls: ['./activity-diary-list.component.scss', '../../../styles.scss', '../../../lib/styles/system-admin-styles.component.scss']
})
export class ActivityDiaryListComponent extends BaseComponent implements AfterViewInit, OnInit {

  log: Logger = new Logger("ActivityDiaryComponent");

  @Input()
  miCaseId: string = "-1";
  @Input()
  activityDiaryId: any | null;

  screenMode: string = "MASTER_LIST";

  userId = -1;
  activityDiaryList = new MatTableDataSource<any>();
  displayColumns = ['createdDate', 'activityDiaryDomain', 'miCaseId','relatedId', 'activityDiaryMessageType', 'messageText', 'createdBy', 'errorFlag'];
  @ViewChild(MatPaginator)
  // matDialogRef?: MatDialogRef<any>
  paginator?: MatPaginator;
  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];

  // Always stores the last retrieved user notification count from the rxjs event that is triggered when a notification
  // count call is done. This is used to prevent an infinite loop where a notification count rxjs event is externally 
  // received by this component (i.e. from the APPLICATION level), which triggers a refresh of the notification list + 
  // a new notification count rxjs event. A comparison of the values is done so calls are not infinitely chained.
  userNotificationCount: number = 0;

  showActivityDiaryFilterOptions:boolean = false;
  activityDiaryDomains: string [] = [];
  activityDiaryMessageTypes: string [] = [];

  // Activity Diary Refresh Interval Object
  refreshIntervalObj: any = null;

  formGroup:FormGroup;

  constructor(
    protected snack: FennecSnackbarService,
    private activityDiaryService: ActivityDiaryService,
    protected route: ActivatedRoute,
    private router: Router,
    private readonly matDialog: MatDialog,
    @Optional() public errorDialogRef: MatDialogRef<ActivityDiaryListComponent>,
    @Optional() public dialogRef: MatDialogRef<AttachmentComponent>
  ) {
    super();
    // This line sets the mode of the list to MI_CASE screen mode.
    this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? "-1";
    if (parseInt(this.miCaseId) >= 0) {
      this.screenMode = "MI_CASE";
    }
    if (this.route.snapshot?.routeConfig?.path?.toUpperCase() === "NOTIFICATIONS") {
      this.screenMode = "NOTIFICATIONS";
    }
    this.formGroup = new FormGroup({
      userId: new FormControl(this.miCaseId === "-1" ? "MINE" : "ALL"),
      activityDiaryDomain: new FormControl(null),
      activityDiaryMessageType: new FormControl(null),
    });

    this.formGroup.valueChanges.subscribe(() => {
      if (this.miCaseId === "-1" && this.formGroup.value["userId"] === "ALL") {
        this.showActivityDiaryFilterOptions = true;
      } else {
        this.showActivityDiaryFilterOptions = false;
      }
      this.getAllActivityList();
    });

    // Subscribe to Activity Diary User Notification changes. This is here to capture notification count
    // changes coming from the setInterval call at the APPLICATION Level. Every (n) interval, a call is made at the
    // application level to see if the logged in user has any notifications. That triggers a rxjs notification that
    // should be received here. If this component is alive, and the user is currently looking at it, and we receive
    // an event here that the notification count has changed in any way (i.e. the biggest use case will be a user 
    // deletes all notifications here, stays on this screen, and a new notification comes in) we want to refresh the
    // notification list.
    //
    // *** Warning - infinite loop here.
    // Turns out that after we refresh the notification list, this component sends out the same rxjs event, so we have
    // a potential infinite loop. Logic is provided to prevent that scenario.
    if (this.screenMode === "NOTIFICATIONS") {
      activityDiaryService.notificationCountUpdated.subscribe((nCount) => {
        let refreshNotificationList = false;
        if (nCount !== this.userNotificationCount) {
          refreshNotificationList = true;
        }
        this.userNotificationCount = nCount;
        if (refreshNotificationList === true) {
          setTimeout(() => {
            this.getNotifications();
          }, 20)
        }
      });
    }

  }

  ngOnInit() {
    this.getActivityDiaryList();
  }

  ngAfterViewInit() {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getActivityDiaryList();
      });
    }
    this.getActivityDiaryDomains();
    this.getActivityDiaryMessageTypes();
  }

  getActivityDiaryList() {
    if (this.screenMode === "NOTIFICATIONS") {
      this.getNotifications();
    } else if (this.miCaseId != "-1") {
      this.getActivityListForMICase();
    } else {
      this.getAllActivityList();
    }
  }

  getActivityDiaryDomains() {
    this.activityDiaryService.getActivityDiaryDomains().subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.activityDiaryDomains = response.data;
      }
    });
  }

  getActivityDiaryMessageTypes() {
    this.activityDiaryService.getActivityDiaryMessageTypes().subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.activityDiaryMessageTypes = response.data;
      }
    });
  }

  getNotifications() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    this.activityDiaryService.getActivityDiaryNotificationsForUser(first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.activityDiaryList.data = response.data;
        this.formatDataForDisplay();
        this.totalRowCount = response.totalRowCount;
        // Push out a notification to listeners in case the notification count has changed.
        this.activityDiaryService.checkForUserNotifications();
      }
    });

  }

  getAllActivityList() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
  
    if (this.formGroup.controls['userId'].value === "MINE") {
      this.activityDiaryService.getActivityDiaryForUserId(first, pageSize).subscribe((response: any) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.activityDiaryList.data = response.data;
          this.totalRowCount = response.totalRowCount;
          this.formatDataForDisplay();
        }
      });
    } else {
      // No filters - essentially - ALL for everyone
      if (this.formGroup.controls['activityDiaryDomain'].value === null && this.formGroup.controls['activityDiaryMessageType'].value === null) {
        this.activityDiaryService.getAllActivityList(first, pageSize).subscribe((response: any) => {
          if (response.hasErrors) {
            this.snack.showErrorSnack(response.consolidatedErrorMessage);
          } else {
            this.activityDiaryList.data = response.data;
            this.totalRowCount = response.totalRowCount;
            this.formatDataForDisplay();
          }
        });
      } else {
        let params: ActivityDiaryQueryRequestPacket = new ActivityDiaryQueryRequestPacket();
        params.activityDiaryDomain = this.formGroup.controls['activityDiaryDomain'].value;
        params.activityDiaryMessageType = this.formGroup.controls['activityDiaryMessageType'].value;
        this.activityDiaryService.getAllActivityDiaryForRequestParams(params, first, pageSize).subscribe((response: any) => {
          if (response.hasErrors) {
            this.snack.showErrorSnack(response.consolidatedErrorMessage);
          } else {
            this.activityDiaryList.data = response.data;
            this.totalRowCount = response.totalRowCount;
            this.formatDataForDisplay();
          }
        });
      }
    }
  }

  getActivityListForMICase() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    // const paramId = this.activeRoute?.snapshot?.paramMap?.get('id');
    this.activityDiaryService.getAllActivityDiaryForMICase(this.miCaseId, first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.activityDiaryList.data = response.data;
        this.totalRowCount = response.totalRowCount;
        this.formatDataForDisplay();
      }
    })
  }

  getActivityDiaryListForId(diaryId: any) {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.activityDiaryService.getAllActivityDiaryForId(diaryId, first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.activityDiaryList.data = response.data;
        this.totalRowCount = response.totalRowCount;
      }
    });
  }

  getActivityDiaryGivenId(activityDiaryId: number): any {
    if (this.activityDiaryList === null || this.activityDiaryList === undefined) {
      return null;
    } else {
      let ad = null;
      this.activityDiaryList.data.forEach((element: any) => {
        if (element.id === activityDiaryId) {
          ad = element;
        }
      });
      return ad;
    }
  }

  navigateToCase(id: number) {
    if (id) {
      this.router.navigateByUrl(`/mi-case/${id}`);
    }
  }

  getRowStyle(row: any) {
    // use toString because of boolean value returned
    if (row.errorFlag.toString() === 'false') {
      return {
        "background-color": "#ccffcc"
      }
    } if (row.errorFlag.toString() === 'true') {
      return {
        "background-color": "#ffb3b3",
        // "background-color": "firebrick",
        "width": "100% 100% !important"
      }
    } else {
      return { "background-color": "beige" }
    }
  }

  openErrorDialog(id: number) {
    let ad: any = this.getActivityDiaryGivenId(id);
    if (ad === null) {
      return;
    }

    // Translate the different kinds of 'data' we may get on an activity diary so we can display it
    // properly if necessary.
    let data: any = null;
    if (ad.data !== null && ad.data !== undefined) {
      data = JSON.parse(ad.data);
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      id: ad.id,
      messageText: ad.messageText,
      data: data,
      activityDiaryMessageType: ad.activityDiaryMessageType
    };
    const dialogRef = this.matDialog.open(ActivityDiaryDialogComponent, matDialogConfig);
  }

  openAttachmentDialog(adRelatedId: number, miCaseId: number | string, activityDiaryDomain: string) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.height = "auto";
    matDialogConfig.width = "100%";
    matDialogConfig.panelClass='custom-mat-dialog-panel';
    matDialogConfig.data = {
      attachmentId: adRelatedId,
      miCaseId: miCaseId,
      context: activityDiaryDomain,
    };
    // const dialogRef = this.matDialog.open(AttachmentComponent, matDialogConfig);
    const dialogRef = this.matDialog.open(ActivityDiaryAttachmentDialogComponent, matDialogConfig);
  }

  formatDate(dateString:string) {
    return DateUtil.getDisplayDate(dateString);
  }

  // Note: This method clears the navigation router by routing to / first, and then re-routing to the requested
  // destination. This fixes the problem of routing only working the first time and then not doing anything
  // after that.
  // Note: expected format of URL is: /(routeurl)/
  //       Example: /mi-case-itemized/
  routeToUrl(url: string, id: number) {
    let toUrl: string = url;
    if (id >= 0) {
      toUrl += id.toString();
    }
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigateByUrl(toUrl));
  }

  routeToItemizedUrl(url: string, id: number, itemizedId: number) {
    let toUrl: string = url;
    if (id >= 0) {
      toUrl += id.toString();
    }
    if (itemizedId >= 0) {
      toUrl += "/";
      toUrl += itemizedId.toString();
    }
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigateByUrl(toUrl));
  }

  routeToItemizedRevisionUrl(url: string, id: number, itemizedRevisionId: number) {
    let toUrl: string = url;
    if (id >= 0) {
      toUrl += id.toString();
    }
    if (itemizedRevisionId >= 0) {
      toUrl += "/";
      toUrl += itemizedRevisionId.toString();
    }
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigateByUrl(toUrl));
  }

  onRefreshList() {
    this.getActivityDiaryList();
  }

  onClearFilters() {
    this.formGroup.controls['activityDiaryDomain'].setValue(null);
    this.formGroup.controls['activityDiaryMessageType'].setValue(null);
  }

  onDeleteAllNotificationsPrompt() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : "Delete all notifications?",
      infoLine1 : "Deletes all notifications including those not currently viewed."
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.deleteAllNotificationsForUser();
      }
    });   
  }

  private deleteAllNotificationsForUser() {
    this.activityDiaryService.deleteAllNotificationsForUser().subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.getActivityDiaryList();
      }
    });
  }

  formatDataForDisplay() {
    if (this.activityDiaryList.data === null || this.activityDiaryList.data === undefined) {
      return;
    }
    this.activityDiaryList.data.forEach((rec) => {
      if (rec.data !== null && rec.data !== undefined && this.isJsonString(rec.data)) {
        var obj = JSON.parse(rec.data);
        rec['attachmentId'] = obj['attachmentId'] !== null && obj['attachmentId'] !== undefined ? obj['attachmentId'] : null;
        rec['beforeData'] = obj['beforeData'];
        rec['afterData'] = obj['afterData'];
        if (rec['beforeData'] !== null && rec['beforeData'] !== undefined && rec['afterData'] !== null && rec['afterData'] !== undefined) {
          let diffProperties = this.identifyPropertyDiffs(JSON.parse(rec['beforeData']), JSON.parse(rec['afterData'])); 
          if (diffProperties.length > 0) {
            rec['diffProperties'] = diffProperties.toString();
          } else {
            rec['diffProperties'] = "No differences detected!";
          }
        }
      }
    }); 
  }

  private isJsonString(str): boolean {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  viewJson(title: string, json: string) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    // Sorts the incoming json string by the property keys
    const sortedJsonString = JSON.stringify(JSON.parse(json), Object.keys(JSON.parse(json)).sort());
    matDialogConfig.data = { 
      title: title,
      jsonContent: JSON.parse(sortedJsonString),
    }
    const dialogRef = this.matDialog.open(ActivityLogJsonDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
      }
    });
  }

  // Note: This should eventually be put in a common utilities class for re-use by other components. 
  // (ex: activity log viewers)
  // Returns a string array of the propery keys that have different VALUES between the objects.
  private identifyPropertyDiffs(obj1: any, obj2: any): string [] {
    const diffKeys: string [] = [];
    if (obj1 === null || obj1 === undefined || obj2 === null || obj2 === undefined) {
      return diffKeys;
    }
    Object.entries(obj1).forEach(([key, value]) => {
      // Does not compare child arrays at this point...
      if (!Array.isArray(value) && value !== obj2[key]) {
        diffKeys.push(key);
      }
    });
    return diffKeys;
  }

}
