import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import {
  BaseComponent,
  FennecSnackbarService,
  Logger,
  MaterialModule
} from 'xf-common';
import { EmailService } from '../email.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivityDiaryDialogComponent } from '../../activity-diary/activity-diary-dialog/activity-diary-dialog.component';
import { SendEmailDialogConfig } from 'projects/xf-common/src/public-api';

@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrl: './send-email-dialog.component.scss'
})
export class SendEmailDialogComponent extends BaseComponent {

  protected log: Logger = new Logger("SendEmailDialogComponent");

  dialogHeader: string = "Send Email";
  formGroup: FormGroup;

  constructor(
    override snack: FennecSnackbarService,
    protected keycloakService: KeycloakService,
    protected emailService: EmailService,
    protected dialog: MatDialog,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public sendEmailDialogConfig: SendEmailDialogConfig,
    public dialogRef: MatDialogRef<ActivityDiaryDialogComponent>
  ) {
    super();
    this.formGroup = this.createFormGroup();
    if (sendEmailDialogConfig?.dialogHeader !== null && sendEmailDialogConfig?.dialogHeader !== undefined) {
      this.dialogHeader = sendEmailDialogConfig.dialogHeader;
    }
   }

   sendEmail = () => {
    const controls = this.formGroup.controls;
    const dto = {
      miCaseId: this.sendEmailDialogConfig.miCaseId,
      toName: controls["toName"].value,
      toAddress: controls["toAddress"].value,
      subject: controls["subject"].value,
      messageBody: controls["messageBody"].value,
    }

    if (this.sendEmailDialogConfig.attachmentId != -1) {
      dto["attachmentId"] = this.sendEmailDialogConfig.attachmentId;
    }

    dto["emailTemplateType"] = "BASIC";
    if (this.sendEmailDialogConfig.sendEmailContext === "IB_REQUEST_LETTER") {
      dto["emailTemplateType"] = "IB_REQUEST_LETTER";
    } else if (this.sendEmailDialogConfig.sendEmailContext === "APPEAL_RESPONSE_PACKET") {
      dto["emailTemplateType"] = "APPEAL_RESPONSE_PACKET";
    }

    this.performXFRequest({
      requestDescription: "Send Email",
      requestFn: this.emailService.sendEmail,
      fnParams: [dto],
      // onComplete: () => {
      //   this.isLoading = false;
      // },
      onSuccess: response => {
        super.showWorkerSubmitSnack("Email Sent!");
        this.close();
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    })
   }

   createFormGroup = () => {
    return new FormGroup({
      toName: new FormControl(this.sendEmailDialogConfig?.inputName ?? "", Validators.required),
      subject: new FormControl(this.sendEmailDialogConfig?.inputSubject ?? "", Validators.required),
      toAddress: new FormControl(this.sendEmailDialogConfig?.inputEmailAddress ?? "", [Validators.required, Validators.email]),
      messageBody: new FormControl(this.sendEmailDialogConfig?.inputBody ?? "", Validators.required)
    });
   }

   close = () => {
    this.dialogRef.close();
   }
}
