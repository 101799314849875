import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseComponent, FennecSnackbarService, MICaseService, Logger } from 'xf-common';
import { HealthCarePlanLobService } from '../health-care-plan-lob.service';
import { HealthCarePlanLOBPacket } from 'projects/xf-common/src/lib/model/net/healthCarePlanLOBPacket';
import { AttachmentPacket, AttachmentService, FilePacket, FileType, FileUtil, SingleChoiceDialogComponent } from 'xf-common';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { HealthCarePlanLOBUpdatePacket } from 'projects/xf-common/src/lib/model/net/healthCarePlanLOBUpdatePacket';

@Component({
  selector: 'app-health-care-plan-lob',
  templateUrl: './health-care-plan-lob.component.html',
  styleUrl: './health-care-plan-lob.component.scss'
})
export class HealthCarePlanLobComponent extends BaseComponent implements OnInit, AfterViewInit {
  log: Logger = new Logger("CreateCaseComponent");

  @Input()
  healthCarePlanId: number = -1;

  private readonly PROVIDER_RPT_LOGO_ATTACHMENT_TYPE: string = "PROVIDER_RPT_LOGO";

  ibRequestTemplateUIConfig: any [] = [
    {
      cardTitle: "Provider Packet Cover Letter",
      attachmentType: "PROVIDER_COVER_LETTER_TEMPLATE",
      genAttachmentType: "PROVIDER_COVER_LETTER"
    },
    {
      cardTitle: "IB Request Template 1",
      attachmentType: "IB_REQUEST_TEMPLATE_1",
      genAttachmentType: "IB_REQUEST_LETTER_1"
    },
    {
      cardTitle: "IB Request Template 2",
      attachmentType: "IB_REQUEST_TEMPLATE_2",
      genAttachmentType: "IB_REQUEST_LETTER_2"
    },
    {
      cardTitle: "IB Request Template 3",
      attachmentType: "IB_REQUEST_TEMPLATE_3",
      genAttachmentType: "IB_REQUEST_LETTER_3"
    }
  ];

  lineOfBusinessList: any [];
  selectedLOB?: string;
  hcPlanLOB?: HealthCarePlanLOBPacket;
  lobSelectFormGroup: FormGroup;
  hcPlanLOBFormGroup: FormGroup;

  uploading: boolean = false;
  providerReportLogoAttachment: any;

  // Holds contents of file prior to uploading. Key is attachment type.
  fileMap: Map<string, File> = new Map<string, File>();

  // Holds name of the file if there is one for each attachment type. This is used for UI display when we haven't
  // actually retrieved the file contents (yet or don't have to).
  fileNameMap: Map<string, string> = new Map<string, string>();

  // Holds a local UI status for each attachment to better present things like the delete button, etc.
  fileStatusMap: Map<string, string> = new Map<string, string>();

  // Holds information on all the attachments to the HC Plan / LOB. Generally used for ease of ui interations and logic.
  attachmentInfoMap: Map<string, any> = new Map<string, any>();

  constructor(
    private attachmentService: AttachmentService,
    protected snack: FennecSnackbarService,
    private healthCarePlanLobService: HealthCarePlanLobService,
    private matDialog: MatDialog,
    private miCaseService: MICaseService,
  ) {
    super();  
    
    this.lobSelectFormGroup = new FormGroup({
      lob: new FormControl()
    });

    this.hcPlanLOBFormGroup = new FormGroup({
      planCode: new FormControl(),
      stateCode: new FormControl()
    });

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.lobSelectFormGroup?.get("lob")?.valueChanges.subscribe(selectedValue => {
      this.selectedLOB = selectedValue;
      this.getHCPLineOfBusiness();
    });

    this.getLinesOfBusiness();
  }

  getLinesOfBusiness() {
    this.performXFRequest({
      requestDescription: "Get Lines of Business",
      requestFn: this.miCaseService.getLinesOfBusiness,
      fnParams: [],
      onSuccess: (data) => {
        this.lineOfBusinessList = data;
        if (this.lineOfBusinessList.length > 0) {
          this.lobSelectFormGroup?.get("lob")?.setValue(this.lineOfBusinessList[0].value);
        }
      }
    });
  }

  getHCPLineOfBusiness() {
    if (this.lobSelectFormGroup?.get("lob")?.value === null) {
      return;
    }

    this.healthCarePlanLobService.getHealthCarePlanLOB(this.healthCarePlanId, this.lobSelectFormGroup?.get("lob")?.value).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.hcPlanLOB = response.data;
        // Get the provider logo and display it if it exists.
        this.getProviderReportLogoBlob();

        // Populate HC Plan / LOB Form Group data (everything except for attachment related info)
        this.hcPlanLOBFormGroup.controls['planCode']?.setValue(this.hcPlanLOB.planCode);
        this.hcPlanLOBFormGroup.controls['stateCode']?.setValue(this.hcPlanLOB.stateCode);

        // Populate fileNameMap and attachmentInfoMap from attachments for UI display.
        this.fileNameMap.clear();
        this.attachmentInfoMap.clear();
        this.hcPlanLOB.attachments.forEach((att) => {
          this.fileNameMap.set(att.attachmentType, att.name);
          this.attachmentInfoMap.set(att.attachmentType, att);
        });

        // Populate fileStatusMap from attachment for UI display tracking.
        this.fileStatusMap.clear();
        this.hcPlanLOB.attachments.forEach((att) => {
          this.fileStatusMap.set(att.attachmentType, 'EXISTING');
        });        

      }
    });

  }

  onSave() {
    const payload = new HealthCarePlanLOBUpdatePacket();
    payload.id = this.hcPlanLOB.id;
    payload.planCode = this.hcPlanLOBFormGroup.controls['planCode'].value;
    payload.stateCode = this.hcPlanLOBFormGroup.controls['stateCode'].value;
    this.healthCarePlanLobService.putHealthCarePlanLOB(payload).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.snack.showSuccessSnack("Update successfull!");
      }
    });
  }  

  onRefreshInfo() {
    this.getHCPLineOfBusiness();
  }

  addFile(input: any) {
    const attachmentType = input.getAttribute('data-attachment-type');
    this.fileMap.set(attachmentType, input.files[0]);
    this.fileNameMap.set(attachmentType, input.files[0].name);
    // Make sure to set the value to null so the input html element resets and the file and be changed / re-uploaded
    input.value = null;
  }  

  downloadAttachment(attachmentType: string) {
    let attachmentId = -1;
    this.hcPlanLOB.attachments.forEach((att) => {
      if (att.attachmentType === attachmentType) {
        attachmentId = att.id;
      }
    });
    if (attachmentId < 0) {
      return;
    }

    this.performXFRequest({
      requestDescription: "Download Attachment",
      requestFn: this.attachmentService.downloadAttachment,
      fnParams: [attachmentId],
      onSuccess: (response: FilePacket) => {
        super.showSuccessSnack("downloading attachment");
        FileUtil.downloadFileInBrowser(response, document.createElement("a"), FileType.PDF, response.fileName);
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  deleteAttachmentConfirmation(attachmentType: string): void {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : "Delete Attachment",
      infoLine1 : "Delete attachment: " + attachmentType + "?"
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        if (attachmentType === this.PROVIDER_RPT_LOGO_ATTACHMENT_TYPE) {
          this.deleteProviderReportLogoAttachment();
        } else {
          this.hcPlanLOB.attachments.forEach((att) => {
            if (att.attachmentType === attachmentType) {
              this.deleteAttachmentRelationalMap(att.attachmentRelationalMapId);
            }
          });
        }
      }
    });
  }

  deleteProviderReportLogoAttachment(): void {
    this.performXFRequest({
      requestDescription: "Delete Attachment",
      requestFn: this.attachmentService.deleteHCPlanLOBlogoAttachment,
      fnParams: [this.hcPlanLOB?.id],
      onSuccess: (response: FilePacket) => {
        super.showSuccessSnack("Provider Report logo removed!");
        this.getHCPLineOfBusiness();
      },
      onComplete: () => {
        this.isLoading = false;
        this.getHCPLineOfBusiness();
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  deleteAttachmentRelationalMap(armId: number): void {
    this.performXFRequest({
      requestDescription: "Delete Attachment Relational Map",
      requestFn: this.attachmentService.deleteAttachmentRelationalMap,
      fnParams: [armId],
      onSuccess: (response: FilePacket) => {
        super.showSuccessSnack("Attachment deleted!");
        this.getHCPLineOfBusiness();
      },
      onComplete: () => {
        this.isLoading = false;
        this.getHCPLineOfBusiness();
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }  

  saveAttachmentToServer(attachmentType: string) {
    if (this.fileMap.get(attachmentType) == null) {
      super.showErrorSnack("Cannot save NULL file!");
      return;
    }

    this.uploading = true;

    FileUtil.convertFileToFilePacket(this.fileMap.get(attachmentType))
      .then((filePacket: FilePacket) => {
        const dto = new AttachmentPacket();
        dto.id = -1;
        dto.name = this.fileMap?.get(attachmentType)?.name;
        dto.miCaseId = -1;
        dto.healthCarePlanLOBId = this.hcPlanLOB.id;
        dto.externalObjectId = "";
        dto.attachmentType = attachmentType;
        dto.filePacket = filePacket;
        dto.publishToWeb = false;

        this.isLoading = true;

        this.performXFRequest({
          requestDescription: "Update Attachment",
          requestFn:
            this.attachmentService.updateAttachment,
          fnParams: [dto],
          onSuccess: (data: boolean) => {
            this.uploading = false;
            this.getHCPLineOfBusiness();
            super.showSuccessSnack("Attachment update successful!");
          },
          onComplete: () => {
            this.uploading = false;
          },
          onError: (error) => {
            super.showErrorSnack(error);
            this.uploading = false;
          }
        });
      })
      .catch((error: any) => {
        const errorMessage = super.processError("Error updating attachment: File size limit exceeded for file! ", error);
        console.log(error);
        super.showErrorSnack(errorMessage);
        this.uploading = false;
      });
  }

  onSaveAttachment(attachmentType: string) {
    this.saveAttachmentToServer(attachmentType);
  } 
  
  disableSave(attachmentType: string): boolean {
    if(
      this.uploading || (
        this.fileMap.get(attachmentType) !== undefined
      )){
        return false;
      }

    return true;
  } 
  
  getProviderReportLogoBlob = () => {
    if (this.hcPlanLOB === null || this.hcPlanLOB === undefined 
      || this.hcPlanLOB.logoAttachmentId === null 
      || this.hcPlanLOB.logoAttachmentId === undefined
    ) 
    {
      this.providerReportLogoAttachment = null;
      this.fileMap.delete(this.PROVIDER_RPT_LOGO_ATTACHMENT_TYPE);
      this.fileNameMap.delete(this.PROVIDER_RPT_LOGO_ATTACHMENT_TYPE);
      this.isLoading = false;
      return;
    }

    const attachmentId = this.hcPlanLOB.logoAttachmentId;

    this.performXFRequest({
      requestDescription: "Download Attachment",
      requestFn: this.attachmentService.downloadAttachment,
      fnParams: [attachmentId],
      onSuccess: (response: FilePacket) => {
        // super.showSuccessSnack("downloading attachment");
        const result = FileUtil.downloadBlob(response, FileType.PDF);
        if(result !== null) {
          this.providerReportLogoAttachment = URL.createObjectURL(result);
          this.fileNameMap.set(this.PROVIDER_RPT_LOGO_ATTACHMENT_TYPE, this.providerReportLogoAttachment);
        }

        this.isLoading = false;
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  } 

  genIBRequestTest(attachmentType: string) {
    if (attachmentType == "PROVIDER_COVER_LETTER_TEMPLATE") {
      this.healthCarePlanLobService.genProviderCoverLetterTest(this.hcPlanLOB.id, attachmentType).subscribe((response: any) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.snack.showWorkerSubmitSnack("Test IB Request Letter Generation submitted!");
        }
      }); 
      return;
    }

    this.healthCarePlanLobService.genIBRequestLetterTest(this.hcPlanLOB.id, attachmentType).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.snack.showWorkerSubmitSnack("Test IB Request Letter Generation submitted!");
      }
    });    
  } 

}
