import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseComponent, FennecSnackbarService, MICaseService, Logger } from 'xf-common';
import { HealthCarePlanLobService } from '../health-care-plan-lob.service';
import { HealthCarePlanLOBPacket } from 'projects/xf-common/src/lib/model/net/healthCarePlanLOBPacket';
import { AttachmentPacket, AttachmentService, FilePacket, FileType, FileUtil, SingleChoiceDialogComponent } from 'xf-common';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { HealthCarePlanLOBUpdatePacket } from 'projects/xf-common/src/lib/model/net/healthCarePlanLOBUpdatePacket';

@Component({
  selector: 'app-health-care-plan-lob-apllvl-apltype',
  templateUrl: './health-care-plan-lob-apllvl-apltype.component.html',
  styleUrl: './health-care-plan-lob-apllvl-apltype.component.scss'
})
export class HealthCarePlanLobAplLvlAplTypeComponent extends BaseComponent implements OnInit, AfterViewInit {
  log: Logger = new Logger("HealthCarePlanLobAplLvlAplTypeComponent");

  @Input()
  healthCarePlanId: number = -1;

  recordList: any [] = [];
  displayedColumns = ["appealLevelTypeLabel", "appealTypeLabel", "appealCoverLetterTemplate"];
  dialogRef?: MatDialogRef<any>
  @ViewChild("page", { read: ElementRef})
  element?: ElementRef
  defaultPageSizeOptions = [10, 20, 25, 50];
  totalRowCount?: number;

  defaultPageSize = 20;

  lineOfBusinessList: any [];
  selectedLOB?: string;
  uploading: boolean = false;

  lobSelectFormGroup: FormGroup;

  // Holds contents of file prior to uploading. Key is attachment type.
  fileContents: File = null;

  // Holds name of file prior to uploading. Key is attachment type.
  fileName: string = null;

  // Holds name of the file if there is one for each attachment type. This is used for UI display when we haven't
  // actually retrieved the file contents (yet or don't have to).
  //fileNameMap: Map<string, string> = new Map<string, string>();  

  // Holds information regarding the current hc plan / lob / appeal level type / appeal type the user is working on
  // for file upload operations.
  hcPlanLobAplLvlTypAplTypId: number = -1;

  constructor(
    private attachmentService: AttachmentService,
    protected snack: FennecSnackbarService,
    private healthCarePlanLobService: HealthCarePlanLobService,
    private matDialog: MatDialog,
    private miCaseService: MICaseService,
  ) {
    super();  
    
    this.lobSelectFormGroup = new FormGroup({
      lob: new FormControl()
    });

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.lobSelectFormGroup?.get("lob")?.valueChanges.subscribe(selectedValue => {
      this.selectedLOB = selectedValue;
      this.getHCPlanLobAppealLevelTypeAppealTypes();
    });

    // Delay the initial population of data a bit since this conflicts with the HC Plan / LOB call
    // that does some of the same 'auto create' logic.
    setTimeout(() => {
      this.getLinesOfBusiness();
    }, 1000);
  }

  getLinesOfBusiness() {
    this.performXFRequest({
      requestDescription: "Get Lines of Business",
      requestFn: this.miCaseService.getLinesOfBusiness,
      fnParams: [],
      onSuccess: (data) => {
        this.lineOfBusinessList = data;
        if (this.lineOfBusinessList.length > 0) {
          this.lobSelectFormGroup?.get("lob")?.setValue(this.lineOfBusinessList[0].value);
        }
      }
    });
  }

  getHCPlanLobAppealLevelTypeAppealTypes() {
    if (this.lobSelectFormGroup?.get("lob")?.value === null) {
      return;
    }

    this.healthCarePlanLobService.getHealthCarePlanLOBAppealLevelTypeAppealTypes(this.healthCarePlanId, this.lobSelectFormGroup?.get("lob")?.value).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.recordList = response.data;       
      }
    });

  }

  onRefreshInfo() {
    this.getHCPlanLobAppealLevelTypeAppealTypes();
  }

  selectForUpload(id: number) {
    this.hcPlanLobAplLvlTypAplTypId = id;
  }

  addFile(input: any) {
    const attachmentType = input.getAttribute('data-attachment-type');
    this.fileContents = input.files[0];
    this.fileName = input.files[0].name;
    // Make sure to set the value to null so the input html element resets and the file and be changed / re-uploaded
    input.value = null;
  }  

  downloadAttachment(attachmentId: number) {

    this.performXFRequest({
      requestDescription: "Download Attachment",
      requestFn: this.attachmentService.downloadAttachment,
      fnParams: [attachmentId],
      onSuccess: (response: FilePacket) => {
        super.showSuccessSnack("downloading attachment");
        FileUtil.downloadFileInBrowser(response, document.createElement("a"), FileType.PDF, response.fileName);
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  // Note: the id here is the attachmentRelationalMapId
  deleteAttachmentConfirmation(id: number): void {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : "Delete Attachment",
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.deleteAttachmentRelationalMap(id);
      }
    });
  }

  deleteAttachmentRelationalMap(armId: number): void {
    this.performXFRequest({
      requestDescription: "Delete Attachment Relational Map",
      requestFn: this.attachmentService.deleteAttachmentRelationalMap,
      fnParams: [armId],
      onSuccess: (response: FilePacket) => {
        super.showSuccessSnack("Attachment deleted!");
        this.getHCPlanLobAppealLevelTypeAppealTypes();
      },
      onComplete: () => {
        this.isLoading = false;
        this.getHCPlanLobAppealLevelTypeAppealTypes();
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  } 

  saveAttachmentToServer(hcPlanLobAplLvlTypeAplTypeId: number, attachmentType: string) {
    if (this.fileContents === null) {
      super.showErrorSnack("Cannot save NULL file!");
      return;
    }

    this.uploading = true;

    FileUtil.convertFileToFilePacket(this.fileContents)
      .then((filePacket: FilePacket) => {
        const dto = new AttachmentPacket();
        dto.id = -1;
        dto.name = this.fileName;
        dto.miCaseId = -1;
        dto.healthCarePlanLOBId = -1;
        dto.healthCarePlanLOBAplLvlTypeAplTypeId = hcPlanLobAplLvlTypeAplTypeId;
        dto.externalObjectId = "";
        dto.attachmentType = attachmentType;
        dto.filePacket = filePacket;
        dto.publishToWeb = false;

        this.isLoading = true;

        this.performXFRequest({
          requestDescription: "Update Attachment",
          requestFn:
            this.attachmentService.updateAttachment,
          fnParams: [dto],
          onSuccess: (data: boolean) => {
            this.uploading = false;
            this.getHCPlanLobAppealLevelTypeAppealTypes();
            super.showSuccessSnack("Attachment update successful!");
          },
          onComplete: () => {
            this.uploading = false;
            this.hcPlanLobAplLvlTypAplTypId = -1;
            this.fileContents = null;
            this.fileName = null;
          },
          onError: (error) => {
            super.showErrorSnack(error);
            this.uploading = false;
          }
        });
      })
      .catch((error: any) => {
        const errorMessage = super.processError("Error updating attachment: File size limit exceeded for file! ", error);
        console.log(error);
        super.showErrorSnack(errorMessage);
        this.uploading = false;
      });
  }

  onSaveAttachment(hcPlanLobAplLvlTypeAplTypeId: number, attachmentType: string) {
    this.saveAttachmentToServer(hcPlanLobAplLvlTypeAplTypeId, attachmentType);
  } 
  
  disableSave(id: number): boolean {
    if(
      this.uploading || (
        id === this.hcPlanLobAplLvlTypAplTypId && this.fileContents !== null)) {
        return false;
      }

    return true;
  } 

  /*
  genIBRequestTest(attachmentType: string) {
    this.healthCarePlanLobService.genIBRequestLetterTest(this.hcPlanLOB.id, attachmentType).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.snack.showWorkerSubmitSnack("Test IB Request Letter Generation submitted!");
      }
    });    
  } */

}
