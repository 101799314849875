<mat-dialog-content class="alt-theme">
  <H1>UB04 Providers</H1>
  <div *ngIf="displayMode === 'EXISTING'" style="display:flex; width: 100%;">
    <div class="provider-bubble">
      <h3 class="provider-bubble-title">Primary Provider</h3>
      <div *ngIf="existingTypePrimary">
        
        <div class="provider-bubble-content">
        <mat-form-field 
          appearance="fill">
          <mat-label>Name</mat-label>
          <input
            matInput
            value={{getPrimaryProviderName()}}
            readonly
            tabindex="-1"
          >
        </mat-form-field>
  
        <mat-form-field 
          appearance="fill">
          <mat-label>NPI Number</mat-label>
          <input
            matInput
            value={{primaryProvider.npiNumber}}
            readonly
            tabindex="-1"
          >
        </mat-form-field>

        <div>
          <p>{{getFormattedAddressLine1(primaryProvider)}}</p>
          <p>{{getFormmattedAddressLine2(primaryProvider)}}</p>
        </div>
      </div>
      <button 
        class="provider-bubble-button"
        mat-button 
        mat-stroked-button
        (click)="onRemoveUB04Provider(primaryProvider.id)">
        <mat-icon>person_remove</mat-icon>
        Remove Primary Provider
      </button>
    </div>

      <div *ngIf="!existingTypePrimary" class="empty-provider-bubble">
        <button 
          class="provider-bubble-button"
          mat-button 
          mat-stroked-button
          (click)="setDisplayMode('SEARCH_PRIMARY')">
          <mat-icon>person_add</mat-icon>
          Select Primary Provider
        </button>
      </div>
    </div>
  
    <div class="provider-bubble">
      <h3 class="provider-bubble-title">Attending Provider</h3>
      <div *ngIf="existingTypeAttending">
        
        <div class="provider-bubble-content">
        <mat-form-field 
          appearance="fill">
          <mat-label>Name</mat-label>
          <input
            matInput
            value={{getAttendingProviderName()}}
            readonly
            tabindex="-1"
          >
        </mat-form-field>
  
        <mat-form-field 
          appearance="fill">
          <mat-label>NPI Number</mat-label>
          <input
            matInput
            value={{attendingProvider.npiNumber}}
            readonly
            tabindex="-1"
          >
        </mat-form-field>

        <div>
          <p>{{getFormattedAddressLine1(attendingProvider)}}</p>
          <p>{{getFormmattedAddressLine2(attendingProvider)}}</p>
        </div>
      </div>

      <button 
        class="provider-bubble-button"
        mat-button 
        mat-stroked-button
        (click)="onRemoveUB04Provider(attendingProvider.id)">
        <mat-icon>person_remove</mat-icon>
        Remove Attending Provider
      </button>
    </div>

      <div *ngIf="!existingTypeAttending" class="empty-provider-bubble">
        <button 
          class="provider-bubble-button"
          mat-button 
          mat-stroked-button
          (click)="setDisplayMode('SEARCH_ATTENDING')">
          <mat-icon>person_add</mat-icon>
          Select Attending Provider
        </button>
      </div>
    </div>

    <div class="provider-bubble">
      <h3 class="provider-bubble-title">Operating Provider</h3>
      <div *ngIf="existingTypeOperating">
        
        <div class="provider-bubble-content">
        <mat-form-field 
          appearance="fill">
          <mat-label>Name</mat-label>
          <input
            matInput
            value={{getOperatingProviderName()}}
            readonly
            tabindex="-1"
          >
        </mat-form-field>
  
        <mat-form-field 
          appearance="fill">
          <mat-label>NPI Number</mat-label>
          <input
            matInput
            value={{operatingProvider.npiNumber}}
            readonly
            tabindex="-1"
          >
        </mat-form-field>

        <div>
          <p>{{getFormattedAddressLine1(operatingProvider)}}</p>
          <p>{{getFormmattedAddressLine2(operatingProvider)}}</p>
        </div>
      </div>
  
      <button 
        class="provider-bubble-button"
        mat-button 
        mat-stroked-button
        (click)="onRemoveUB04Provider(operatingProvider.id)">
        <mat-icon>person_remove</mat-icon>
        Remove Operating Provider
      </button>
    </div>

      <div *ngIf="!existingTypeOperating" class="empty-provider-bubble">
        <button 
          class="provider-bubble-button"
          mat-button 
          mat-stroked-button
          (click)="setDisplayMode('SEARCH_OPERATING')">
          <mat-icon>person_add</mat-icon>
          Select Operating Provider
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="displayMode === 'EXISTING'" style="width: 100%; display: flex; justify-content: center; margin-top: 12px;">
    <div class="provider-bubble">
      <h3 class="provider-bubble-title">Other Provider</h3>
      <div *ngIf="otherProviderA">
        
        <div class="provider-bubble-content">
        <mat-form-field 
          appearance="fill">
          <mat-label>Name</mat-label>
          <input
            matInput
            value={{getOtherProviderAName()}}
            readonly
            tabindex="-1"
          >
        </mat-form-field>
  
        <mat-form-field 
          appearance="fill">
          <mat-label>NPI Number</mat-label>
          <input
            matInput
            value={{otherProviderA.npiNumber}}
            readonly
            tabindex="-1"
          >
        </mat-form-field>

        <div>
          <p>{{getFormattedAddressLine1(otherProviderA)}}</p>
          <p>{{getFormmattedAddressLine2(otherProviderA)}}</p>
        </div>
      </div>
      <button 
        class="provider-bubble-button"
        mat-button 
        mat-stroked-button
        (click)="onRemoveUB04Provider(otherProviderA.id)">
        <mat-icon>person_remove</mat-icon>
        Remove Other Provider
      </button>
    </div>

      <div *ngIf="!otherProviderA" class="empty-provider-bubble">
        <button 
          class="provider-bubble-button"
          mat-button 
          mat-stroked-button
          (click)="setDisplayMode('SEARCH_OTHER')">
          <mat-icon>person_add</mat-icon>
          Select Other Provider
        </button>
      </div>
    </div>

    <div class="provider-bubble" *ngIf="otherProviderA">
      <h3 class="provider-bubble-title">Other Provider</h3>
      <div *ngIf="otherProviderB">
        
        <div class="provider-bubble-content">
        <mat-form-field 
          appearance="fill">
          <mat-label>Name</mat-label>
          <input
            matInput
            value={{getOtherProviderBName()}}
            readonly
            tabindex="-1"
          >
        </mat-form-field>
  
        <mat-form-field 
          appearance="fill">
          <mat-label>NPI Number</mat-label>
          <input
            matInput
            value={{otherProviderB.npiNumber}}
            readonly
            tabindex="-1"
          >
        </mat-form-field>

        <div>
          <p>{{getFormattedAddressLine1(otherProviderB)}}</p>
          <p>{{getFormmattedAddressLine2(otherProviderB)}}</p>
        </div>
      </div>
      <button 
        class="provider-bubble-button"
        mat-button 
        mat-stroked-button
        (click)="onRemoveUB04Provider(otherProviderB.id)">
        <mat-icon>person_remove</mat-icon>
        Remove Other Provider
      </button>
    </div>

      <div *ngIf="!otherProviderB" class="empty-provider-bubble">
        <button 
          class="provider-bubble-button"
          mat-button 
          mat-stroked-button
          (click)="setDisplayMode('SEARCH_OTHER')">
          <mat-icon>person_add</mat-icon>
          Select Other Provider
        </button>
      </div>
    </div>
  </div>

  <!-- displayMode == SEARCH_{providerType} -->
  <div *ngIf="isSearch()" class="provider-search-header">
    <h2>Provider Search</h2>

    <div>
      <button mat-button mat-stroked-button (click)="setDisplayMode('ADD_PROVIDER_' + getSelectedType())">
        <mat-icon>person_add</mat-icon> New Provider
      </button>

      <button mat-button mat-stroked-button (click)="setDisplayMode('EXISTING')">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </div>

  <app-name-search-prompt 
    #providerNameSearch 
    [hidden]="!isSearch()" 
    [searchMode]="'UB04_PROVIDER'" 
    [selectMode]="displayMode.split('_').length > 1 ? displayMode.split('_')[1] : 'MULTI_SELECT'">
  </app-name-search-prompt>
  

  <!-- Provider Add -->
  <div *ngIf="displayMode.startsWith('ADD_PROVIDER')" class="provider-search-header">
    <h2>Add New Provider</h2>

    <button mat-button mat-stroked-button (click)="setDisplayMode('SEARCH_' + getSelectedType())">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>

  <app-ub04-add-provider-2 #providerAdd [hidden]="!displayMode.startsWith('ADD_PROVIDER')"></app-ub04-add-provider-2>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls">
  <mat-checkbox class="mark-complete-checkbox" [checked]="statusTrackComplete" (change)="toggleStatusTrackComplete()">
    Mark Complete
  </mat-checkbox>
  
  <button 
    mat-flat-button 
    (click)="onSubmit()" 
    [disabled]="!providerAdd?.formGroup?.valid" 
    color="accent"
    *ngIf="displayMode.startsWith('ADD_PROVIDER')">
      <mat-icon>save</mat-icon> Save
  </button> 

  <button mat-flat-button color="primary" (click)="onCancel()">
    <mat-icon>cancel</mat-icon> Close
  </button>
</mat-dialog-actions>
